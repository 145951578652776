import React, { Suspense, lazy } from "react";
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import "./assets/css/icofont.min.css";
import "./assets/css/animate.min.css";
import "./assets/css/bootstrap.min.css";
import "./../node_modules/slick-carousel/slick/slick.css";
import "./../node_modules/slick-carousel/slick/slick-theme.css";
import "../node_modules/react-modal-video/css/modal-video.min.css";
import FloatingWhatsAppButton from "./components/Buttons/WhatsAppBtn";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LandingPage from "./components/LandingPage";
import Hotels from "./components/Hotels";

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/home" element={<LandingPage />} />
          <Route path="/banner" element={<LandingPage />} />
          <Route path="/speakers" element={<LandingPage />} />
          <Route path="/programa" element={<LandingPage />} />
          <Route path="/pricing" element={<LandingPage />} />
          <Route path="/aliados-estrategicos" element={<LandingPage />} />
          <Route path="/contacto" element={<LandingPage />} />
          <Route path="/hoteles" element={<Hotels />} />
        </Routes>
      </div>
      {/* <ModalB/> */}
      {/* <AppRouter /> */}
      {/* <WelcomeModal /> */}
      {/* <FloatingWhatsAppButton /> */}
    </Router>
  );
}

export default App;
