import React, { useEffect } from "react";
import Footer from "../Footer";
import "./styles.css";

export default function Hotels() {
  // useEffect(() => {
  //   function scrollToTop() {
  //     window.scrollTo({
  //       top: 0,
  //       behavior: "smooth",
  //     });
  //   }

  //   scrollToTop();
  // }, []);

  return (
    <>
      <section className="schedule-area bg-image">
        <div className="d-flex justify-content-center">
          <img
            width={650}
            alt=""
            src={require("./../../assets/foro-go/titulos/web/hoteles_sm.webp")}
          />
        </div>
        <div className="div-card-container">
          <div className=" bg-dark div-card">
            <div className="d-flex justify-content-center">
              <img
                width={200}
                alt="hotels img"
                src={require("./../../assets/foro-go/hoteles/hotel-Metria.webp")}
              />
            </div>
            <hr style={{ borderTop: "2px solid #7CFF01" }} />
            <br />
            <h3 className="text-center" style={{ color: "#ffff" }}>
              Metria Hotel
            </h3>
            <div className="d-flex justify-content-center">
              <ul className="">
                <li>Habitación Sencilla ó doble sin desayuno: $1,560 MXN</li>
                <li>Habitación Sencilla con desayuno: $1,780 MXN</li>
                <li>Habitación Doble con desayuno: $2,000 MXN</li>
                <li>
                  Persona extra en habitación doble solo hospedaje: $300 MXN
                </li>
                <li>
                  Se incluye estacionamiento como parte de las cortesías del
                  hotel.
                </li>
                <li>Botones $60 MXN. por persona (entrada y salida)</li>
                <li>Camaristas $50 MXN. por habitación por noche.</li>
              </ul>
            </div>
            <br />
            <h3 style={{ color: "#ffff" }} className="text-center">
              Contacto
            </h3>
            <div className="text-center">
              <b>Elizabeth Gutiérrez</b>
              <br />
              <b>Tel. 477 689 1856 (ext. 514)</b>
              <br />
              <b>elizabeth.gutierrez@metriahotel.com</b>
            </div>
            <br />
            <div className="text-center">
              <h3 style={{ color: "#fff" }}>Código de reserva</h3>
              <b style={{ color: "#7CFF01" }}>Foro go</b>
            </div>
          </div>

          <div className=" bg-dark div-card">
            <div className="d-flex justify-content-center">
              <img
                alt=""
                width={200}
                src={require("./../../assets/foro-go/hoteles/hotel-NuevaEstancia.webp")}
              />
            </div>
            <hr style={{ borderTop: "2px solid #7CFF01" }} />
            <br />
            <h3 className="text-center" style={{ color: "#ffff" }}>
              Hotel La Nueva Estancia
            </h3>
            <div className="d-flex justify-content-center">
              <ul className="">
                <li>
                  Jr. Suite Domo-Alberca – Deluxe sencilla o doble $1,498.00{" "}
                </li>
                <li>Jr. Suite Piso Ejecutivo sencilla o doble $2,498.00</li>
                <li>Master Suite sencilla o doble $2,498.00</li>
                <li>Master Suite Dluxe sencilla o doble $3,598.00</li>
                <li>Suite Presidencial sencilla o doble $4,598.00</li>
                <li>Incluye desayuno americano</li>
              </ul>
            </div>
            <br />
            <h3 style={{ color: "#ffff" }} className="text-center">
              Contacto
            </h3>
            <div className="text-center">
              <b>Martín Jiménez Chávez</b>
              <br />
              <b>Tel. +52 (477) 637 2051</b>
              <br />
              <b>mjimenez@grupo330.com.mx</b>
            </div>
            <br />
            <div className="text-center">
              <h3 style={{ color: "#fff" }}>Código de reserva</h3>
              <b style={{ color: "#7CFF01" }}>COFOCE 02 – 24 GO</b>
            </div>
          </div>

          {/* <div className=" bg-dark div-card">
            <div className="d-flex justify-content-center">
              <img
                alt=""
                width={100}
                src={require("./../../assets/foro-go/hoteles/hotel-one.webp")}
              />
            </div>
            <hr style={{ borderTop: "2px solid #7CFF01" }} />
            <br />
            <h3 className="text-center" style={{ color: "#ffff" }}>
              Hotel One Poliforum
            </h3>
            <div className="d-flex justify-content-center">
              <ul className="">
                <li>Desayunos e Impuestos incluidos $1,079.00</li>
                <li>Del 17 al 19 de octubre</li>
              </ul>
            </div>
            <br />
            <h3 style={{ color: "#ffff" }} className="text-center">
              Contacto
            </h3>
            <div className="text-center">
              <b>Jorge Molina</b>
              <br />
              <b>ventas1lepo@posadas.com</b>
              <br />
              <b>Tel. 477 101 8500 - 477 335 7517</b>
            </div>
            <br />
            <div className="text-center">
              <h3 style={{ color: "#fff" }}>Código de reserva</h3>
              <b style={{ color: "#7CFF01" }}>G1NSW9@OLP</b>
            </div>
          </div> */}

          <div className=" bg-dark div-card">
            <div className="d-flex justify-content-center">
              <img
                alt=""
                width={100}
                src={require("./../../assets/foro-go/hoteles/hs.webp")}
              />
            </div>
            <hr style={{ borderTop: "2px solid #7CFF01" }} />
            <br />
            <h3 className="text-center" style={{ color: "#ffff" }}>
              HOTSSON SMART
            </h3>
            <div className="d-flex justify-content-center">
              <ul className="">
                <li>
                  HS Smart, Centro Max: $1,596.50 incluye desayuno buffete{" "}
                </li>
                <li>
                  Cargo por 2do desayuno en caso de ocupación doble, $192.50
                  neto.
                </li>
                <li>
                  Desayuno en restaurante “Los Vitrales”, Entre semana en
                  horario de 06:00 a 12:00 hrs. Sábado y domingo 07:00 a 12:00
                  hrs.
                </li>
              </ul>
            </div>
            <br />
            <h3 style={{ color: "#ffff" }} className="text-center">
              Contacto
            </h3>
            <div className="text-center">
              <br />
              <b>Margarita Pedroza (Ejecutiva de ventas)</b>
              <br />
              <b>Tel. 477 267-3000 EXT 2013</b>
              <br />
              <b>mpedroza@hotssonsmart.com</b>
              <br />
            </div>
            <br />
            {/* <div className="text-center">
              <h3 style={{ color: "#fff" }}>Código de reserva</h3>
              <b style={{ color: "#7CFF01" }}>FORO GO</b>
            </div> */}
          </div>

          {/* <div className=" bg-dark div-card">
            <div className="d-flex justify-content-center">
              <img
                alt=""
                width={100}
                src={require("./../../assets/foro-go/hoteles/hampton_v2.webp")}
              />
            </div>
            <hr style={{ borderTop: "2px solid #7CFF01" }} />
            <br />
            <h3 className="text-center" style={{ color: "#ffff" }}>
              Hampton INN
            </h3>
            <div className="d-flex justify-content-center">
              <ul className="">
                <li>Habitación Sencilla o Doble: $1,320</li>
                <li>Persona Extra: $300.00</li>
              </ul>
            </div>
            <br />
            <h3 style={{ color: "#ffff" }} className="text-center">
              Contacto
            </h3>
            <div className="text-center">
              <b>Elizabeth Gutiérrez</b>
              <br />
              <b>yadhira.estrada@hilton.com</b>
              <br />
              <b>CC: dai.hernandez@hilton.com</b>
              <br />
              <b>Tel. 477 740-3219</b>
              <br />
              <b>WhatsApp 477 558 9209</b>
            </div>
            <br />
            <div className="text-center">
              <h3 style={{ color: "#fff" }}>Código de reserva</h3>
              <b style={{ color: "#7CFF01" }}>FORO GO / FGO</b>
            </div>
          </div> */}
        </div>
      </section>
      <Footer />
    </>
  );
}
