import React from "react";
import { Link, useNavigate } from "react-router-dom";
import useStore from "../../Zustand";
import LazyLoad from "react-lazy-load";

export default function Footer() {
  const onClickFunction = useStore((state) => state.onClickFunction);
  const navigate = useNavigate();
  /* eslint-disable */
  const onClick = (key) => {
    switch (key) {
      case 1:
        analytics.track("Botón - Aliados estrategicos (footer)", {
          eventId: "acd65383-5b5f-49af-ae1e-e2ac591ee393",
          section: "footer",
          action: "focus",
        });
        onClickFunction(4);
        break;
      case 2:
        analytics.track("Botón - hoteles (footer)", {
          eventId: "acd65383-5b5f-49af-ae1e-e2ac591ee393",
          section: "footer",
          action: "redirect",
        });
        navigate("/hoteles");
        console.log("first");
        break;
      case 3:
        analytics.track("Botón - Boletos (footer)", {
          eventId: "acd65383-5b5f-49af-ae1e-e2ac591ee393",
          section: "footer",
          action: "focus",
        });
        onClickFunction(3);
        break;
      case 4:
        analytics.track("Botón - Aviso de privacidad (footer)", {
          eventId: "acd65383-5b5f-49af-ae1e-e2ac591ee393",
          section: "footer",
          action: "redirect",
        });
        navigate("/aviso");
        break;
      case 5:
        navigate("/terminos");
        analytics.track("Botón - Terminos y condiciones  (footer)", {
          eventId: "acd65383-5b5f-49af-ae1e-e2ac591ee393",
          section: "footer",
          action: "redirect",
        });
        break;
      case 6:
        analytics.track("Botón - Blog (footer)", {
          eventId: "acd65383-5b5f-49af-ae1e-e2ac591ee393",
          section: "footer",
          action: "redirect",
        });
        window.open("https://blog.foro-go.com/");
        break;

      default:
        break;
    }
  };

  return (
    <footer id="contacto" className="footer-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-6">
            <div className="single-footer-widget">
              <h3>Contacto</h3>
              <p className="location">
                <i className="icofont-google-map"></i> Plaza de la Paz 102 Int.
                11, Puerto Interior. 36275, Silao, México
              </p>
              <span>
                <i className="icofont-email"></i>{" "}
                <a
                  className="contact-authority"
                  href="mailto:soporte.forogo@guanajuato.gob.mx"
                >
                  soporte.forogo@guanajuato.gob.mx
                </a>
              </span>
              <span>
                <i className="icofont-phone"></i>{" "}
                <a className="contact-authority" href="tel:+524773999182">
                  477 399 91 82
                </a>
              </span>
              <br />
              <br />
              <div className="logo">
                <Link to="/">
                  <LazyLoad>
                    <img
                      width={300}
                      src={require("../../assets/foro-go/gto-cofoce_logos.png")}
                      alt="logo"
                    />
                  </LazyLoad>
                </Link>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-6">
            <div className="single-footer-widget">
              <ul className="social-links">
                <li>
                  <Link
                    onClick={() => {
                      window.open("https://www.facebook.com/ForoGOgto/");
                    }}
                    to=""
                    className="facebook"
                    target="_blank"
                  >
                    <i className="icofont-facebook"></i>
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://www.youtube.com/channel/UCmOA92mD6BViJfFqLEoXC8g"
                    className="youtube"
                    target="_blank"
                  >
                    <i className="icofont-youtube-play"></i>
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://mx.linkedin.com/in/foro-go"
                    className="linkedin"
                    target="_blank"
                  >
                    <i className="icofont-linkedin"></i>
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://www.instagram.com/forogogto/"
                    className="instagram"
                    target="_blank"
                  >
                    <i className="icofont-instagram"></i>
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://www.tiktok.com/discover/forogo?lang=es"
                    className="tiktok"
                    target="_blank"
                  >
                    <i className="fa-brands fa-tiktok"></i>
                  </Link>
                </li>
                {/* <li>
                  <Link
                    to="https://www.tiktok.com/discover/forogo?lang=es"
                    className="x"
                    target="_blank"
                  >
                    <i className="fa-brands fa-x"></i>
                  </Link>
                </li> */}
              </ul>
            </div>
          </div>

          <div className="col-lg-12">
            <div className="copyright-area">
              <div className="logo">
                <Link to="/">
                  <LazyLoad>
                    <img
                      width={200}
                      src={require("../../assets/foro-go/forogo_blanco.png")}
                      alt="logo"
                    />
                  </LazyLoad>
                </Link>
              </div>
              <ul>
                <li>
                  <Link onClick={() => onClick(1)} to="#">
                    Aliados Estratégicos
                  </Link>
                </li>
                <li>
                  <Link onClick={() => onClick(2)} to="/hoteles">
                    Hoteles
                  </Link>
                </li>
                <li>
                  <Link onClick={() => onClick(3)} to="#">
                    Boletos
                  </Link>
                </li>
                <li>
                  <Link onClick={() => onClick(4)} to="/aviso">
                    Aviso de Privacidad
                  </Link>
                </li>
                <li>
                  <Link onClick={() => onClick(5)} to="/terminos">
                    Términos y Condiciones
                  </Link>
                </li>
                <li>
                  <Link onClick={() => onClick(6)} to="" target="_blank">
                    Blog
                  </Link>
                </li>
              </ul>
              <p style={{ color: "#01fff4" }}>
                <i
                  style={{ color: "#01fff4" }}
                  className="icofont-copyright"
                ></i>{" "}
                Todos los derechos reservados
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
