import React, { useEffect } from "react";
import { Element, scroller } from "react-scroll";
import { useLocation } from "react-router-dom";
import MainBanner from "./MainBanner";
import Carousel from "./Carousel";
import Speakers from "./Speakers";
import EventSchedules from "./EventSchedule";
import Pricing from "./Pricing";
import Partner from "./Partner";
import Footer from "./Footer";
import NavigationBar from "./NavigationBar";
import WhatsAppBtn from "./../components/Buttons/WhatsAppBtn";
import back from "./../assets/foro-go/backgrond/paginaEnConstruccion.jpg";

export default function LandingPage() {
  const location = useLocation();

  useEffect(() => {
    const section = location.pathname.substring(1);
    if (section) {
      scroller.scrollTo(section, {
        duration: 500,
        smooth: true,
      });
    }
  }, [location]);

  return (
    <div>
     <img src={back} alt="img" style={{ width: '100%', height: '100vh', objectFit: 'cover' }} />
      {/* <NavigationBar />
      <Element name="home">
        <MainBanner />
      </Element>
      <Element name="banner">
        <Carousel />
      </Element>
      <Element name="speakers">
        <Speakers />
      </Element>
      <Element name="programa">
        <EventSchedules name="programa" />
      </Element>
      <Element name="pricing">
        <Pricing />
      </Element>
      <Element name="aliados-estrategicos">
        <Partner name="aliados-estrategicos" />
      </Element>
      <Element name="contacto">
        <Footer />
      </Element>
      <WhatsAppBtn /> */}
    </div>
  );
}
