import { create } from "zustand";
import zukeeper from "zukeeper";
import axios from "axios";

export const axiosInstance = axios.create({
  baseURL: "https://api.eventonline.mx",
});

const useStore = create(
  zukeeper((set) => ({
    viewMain: 0,
    bears: 1,
    increasePopulation: () => set((state) => ({ bears: state.bears + 1 })),
    removeAllBears: (data) => set({ bears: data }),

    contactLocation: "",
    contactEmail: "",
    contactPhone: "",
    contactData: {},
    socialData: 0,

    incrementMain: () => set((state) => ({ viewMain: state.viewMain + 1 })),
    viewCarousel: 0,
    incrementCarousel: () =>
      set((state) => ({ viewCarousel: state.viewCarousel + 1 })),
    viewSpeakers: 0,
    incrementSpeakers: () =>
      set((state) => ({ viewSpeakers: state.viewSpeakers + 1 })),
    viewSchedule: 0,
    programList: 0,
    incrementSchedule: () =>
      set((state) => ({ viewSchedule: state.viewSchedule + 1 })),
    viewPricing: 0,
    incrementPricing: () =>
      set((state) => ({ viewPricing: state.viewPricing + 1 })),
    viewPartner: 0,
    incrementPartner: () =>
      set((state) => ({ viewPartner: state.viewPartner + 1 })),
    viewFooter: 0,
    incrementFooter: () =>
      set((state) => ({ viewFooter: state.viewFooter + 1 })),
    setOnClickFunction: (newOnClickFunction) =>
      set({ onClickFunction: newOnClickFunction }),
    speakersInfo: [
      // {
      //   id: 1,
      //   name: "Bruce Dickinson",
      //   description: "Vocalista de Iron Maiden y Business Angel",
      //   more: "Vocalista de la banda de rock Iron Maiden, piloto comercial, business angel, empresario y pensador de negocios creativos. Fue piloto y director de marketing de Astraeus Airlines, una empresa que alquilaba aviones de otras compañías. Es también el hombre detrás de la empresa Caerdav (anteriormente Cardiff Aviation), un proyecto financiado por el gobierno, que se especializa en el mantenimiento de aviones comerciales de Airbus y Boeing. Actualmente también participa en una empresa del sector aeronáutico dedicada a la fabricación de aviones de transporte híbridos y en Pouncer, el increíble dron desarrollado por Windhorse Aerospace que transporta de manera segura ayuda humanitaria a zonas de desastre de difícil acceso.",
      //   image: require("../assets/foro-go/speakers/webp/bruce-dickinson.webp"),
      //   social: {
      //     webSite: "",
      //     facebook: "https://www.facebook.com/BruceDickinsonForever",
      //     instagram: "https://www.instagram.com/brucedickinsonofficial/?hl=es",
      //     twitter: "https://twitter.com/BruceD_Maiden",
      //     youtube: "",
      //     linkedIn: "",
      //   },
      // },
      // {
      //   id: 2,
      //   name: "Randi Zuckerberg",
      //   description: "Founder & CEO Zuckerberg Media and HUG",
      //   more: "Nominada al Emmy, emprendedora, inversionista, autora de bestsellers y una reconocida personalidad de los medios tecnológicos. Es fundadora y directora ejecutiva de Zuckerberg Media y HUG, donde tiene la misión de apoyar a los empresarios actuales y futuros a través de inversiones, tutorías y medios. La experiencia única de Randi la coloca a la vanguardia tanto de la tecnología como de las artes. Fue una de las primeras empleadas de Facebook y la creadora de Facebook Live, estando al frente de la creación de la Web 2.0 para billones de personas y ahora ella se encuentra uniendo ambos mundos rumbo a la Web 3.0.",
      //   image: require("../assets/foro-go/speakers/webp/randi-zuckerberg.webp"),
      //   social: {
      //     webSite: "",
      //     facebook: "https://www.facebook.com/randizberg/",
      //     instagram: "",
      //     twitter: "https://twitter.com/randizuckerberg",
      //     youtube: "https://www.youtube.com/@RandiZuckerbergOfficial",
      //     linkedIn: "https://www.linkedin.com/in/randizuckerberg",
      //   },
      // },
      // {
      //   id: 3,
      //   name: "Katya Echazarreta",
      //   description: "1er mujer mexicana astronauta en viajar al espacio",
      //   more: "Ingeniera electrónica y divulgadora científica mexicana. En junio de 2022 se convirtió en la primera mujer Mexicana en viajar al espacio exterior como parte de la misión Blue Origin NS-21 a bordo de una nave espacial New Shepard. Katya trabajó como ingeniera en la NASA en misiones como el Rover Perseverance y Europa Clipper, donde fue líder de pruebas y electrónica. Es presentadora de varios segmentos de televisión populares, como “Mission Unstoppable” con Miranda Cosgrove. Desde su vuelo espacial, ha decidido continuar con su trabajo humanitario en apoyo de una Industria Espacial y una Agencia Espacial más fuerte en México.",
      //   image: require("../assets/foro-go/speakers/webp/katya-echarrieta.webp"),
      //   social: {
      //     webSite: "",
      //     facebook: "",
      //     instagram: "https://www.instagram.com/katvoltage/",
      //     twitter: "https://www.facebook.com/randizberg/",
      //     youtube: "",
      //     linkedIn: "",
      //   },
      // },
      // {
      //   id: 4,
      //   name: "Sergio Nava",
      //   description:
      //     "Experto en psicología y liderazgo empresarial, Cofundador de de WeWow",
      //   more: "Psicólogo especialista en comportamiento organizacional, cuenta con estudios en Harvard en liderazgo y estrategia de negocios. Fue CEO y fundador de Upper Talent; trajo consigo la alianza con Delivering Happiness en México como parte del desarrollo en la Experiencia del Cliente y la felicidad en el Trabajo. Actualmente es Cofundador de WeWow firma líder de capital humano en temas de talento, liderazgo y cultura organizacional en latinoamérica y es autor del libro “Amplificadores: La psicología detrás de líderes y equipos que se convierten en verdaderos Rockstars”.",
      //   image: require("../assets/foro-go/speakers/webp/sergio-nava.webp"),
      //   social: {
      //     webSite: "",
      //     facebook: "https://www.facebook.com/SergioNavaMX/",
      //     instagram: "",
      //     twitter: "https://twitter.com/sergionavamx",
      //     youtube: "",
      //     linkedIn: "https://www.linkedin.com/in/sergionavamx",
      //   },
      // },
      // {
      //   id: 5,
      //   name: "Roger Guerrero",
      //   description:
      //     "Jefe de automatización de fábrica y control de movimiento en SIEMENS",
      //   more: "Con una larga trayectoria en Siemens, Roger ha desempeñado varios cargos en México y Alemania. Ingeniero industrial graduado de la Universidad de la Salle de México, con especialidad en Dirección industrial por la misma institución. Ha realizado dos diplomados de Management en el ITAM. Actualmente tiene a su cargo dos unidades de Negocios dentro de la División de Digital Industries. Adicionalmente es Project Manager Certificado gestionando varios proyectos a su cargo Tiene una larga lista de logros a lo largo de su carrera como Unidad de negocio más rentable, con mayor crecimiento, por hacer mención de algunos.",
      //   image: require("../assets/foro-go/speakers/webp/roger-guerrero.webp"),
      //   social: {
      //     webSite: "",
      //     facebook: "",
      //     instagram: "",
      //     twitter: "",
      //     youtube: "",
      //     linkedIn: "",
      //   },
      // },
      // {
      //   id: 6,
      //   name: "Bob Jones",
      //   description: "Director en Zefyr Consulting",
      //   more: "Bob Jones es un profesional experimentado en la cadena de suministro con más de 25 años de experiencia, reconocido como un agente de cambio y Principal en Zefyr durante los últimos 3 años. Con experiencia en planificación, innovación, abastecimiento y fabricación global, así como en NearShoring, Bob Jones posee una comprensión integral del ecosistema de la cadena de suministro de prendas de vestir y accesorios, especialmente en las Américas y Asia. Bob Jones ha vivido en Asia y ha pasado los últimos 10 años desarrollando soluciones de cadena de suministro de NearShoring para Nike. Con una mentalidad orientada al futuro y una habilidad para identificar tendencias emergentes, Bob Jones ha posicionado a las organizaciones para un crecimiento sostenible y ventaja competitiva, ganándose una reputación como una figura altamente respetada y de impacto en el campo de la cadena de suministro con Zefyr Consulting. Junto con Dave Kelley, nuestro objetivo es elevar y optimizar las cadenas de suministro para marcas, fábricas y proveedores de materiales.",
      //   image: require("../assets/foro-go/speakers/webp/bob-jones.webp"),
      //   social: {
      //     webSite: "",
      //     facebook: "",
      //     instagram: "",
      //     twitter: "",
      //     youtube: "",
      //     linkedIn: "https://www.linkedin.com/in/bob-jones-supplychain/",
      //   },
      // },
      // {
      //   id: 7,
      //   name: "Dave Kelley",
      //   description: "Fundador y Socio Principal de Zefyr Consulting",
      //   more: "Líder probado, entrenador y motivador que ofrece resultados empresariales integrales de principio a fin. Fundador y Principal Portland, OR 2019-presente Fundador y Principal Portland, OR 2019-presente ● Fundador y miembro principal - comprometido con la optimización de asociaciones en las cadenas de suministro de calzado, prendas de vestir y equipos. ● Especializado en la optimización de la cadena de suministro para elevar el rendimiento en el espacio entre las marcas, los fabricantes de nivel 1 y los proveedores de materiales de nivel 2. ● Fundador y miembro principal - comprometido con la optimización de asociaciones en las cadenas de suministro de calzado, prendas de vestir y equipos. ● Especializado en la optimización de la cadena de suministro para elevar el rendimiento en el espacio entre las marcas, los fabricantes de nivel 1 y los proveedores de materiales de nivel 2.",
      //   image: require("../assets/foro-go/speakers/webp/dave-kelly.webp"),
      //   social: {
      //     webSite: "https://www.zefyrconsulting.com/",
      //     facebook: "",
      //     instagram: "",
      //     twitter: "",
      //     youtube: "https://www.linkedin.com/in/zefyr/",
      //     linkedIn: " https://www.youtube.com/channel/UCsvgCGT4axPO6GB-aKdMBRw",
      //   },
      // },
      // {
      //   id: 8,
      //   name: "José de la Garza",
      //   description: "Senior Sourcing Specialist RECARO",
      //   more: "Especialista sénior en procuración para RECARO Aeroespacial. José De La Garza es un profesional en el Manejo de la Cadena de Suministro con más de veinte años de experiencia progresiva y diversa en Manejo de la Cadena Internacional de Suministro, Compras Internacional, Manejo de Inventario, Logística multimodal. Trabajando con empresas como General Electric, Atlas Copco, y Ford Motor Co. en entornos diversos y competitivos. Con enfoque en compras globales y abastecimiento de componentes de fabricación. José De La Garza está capacitado para implementar cambios positivos y rentables en las operaciones, metodologías y políticas de la cadena de suministro para mejorar el desempeño, capturar oportunidades y facilitar la interacción de los diferentes equipos en manufactura.",
      //   image: require("../assets/foro-go/speakers/webp/jose-de-la-garza.webp"),
      //   social: {
      //     webSite: "",
      //     facebook: "",
      //     instagram: "",
      //     twitter: "",
      //     youtube: "",
      //     linkedIn: "",
      //   },
      // },
      // {
      //   id: 9,
      //   name: "Mick Ebeling",
      //   description: "Creando infinitas posibilidades",
      //   more: "Nombrado por la revista Fortune como uno de los 50 Mejores Líderes del Mundo, galardonado con el Premio Muhammad Ali Humanitario del Año y catalogado como una de las personas creativas más influyentes del mundo por The Creativity 50, Mick Ebeling ha iniciado un movimiento de innovación pragmática e inspiradora. Como productor y cineasta de profesión, y ahora fundador y CEO de Not Impossible, Mick ha dedicado su vida a aprovechar el poder de la innovación, la tecnología y las historias para cambiar el mundo. El mantra de Mick, 'comprométete y luego resuelve cómo hacerlo', permite un enfoque único para resolver problemas, reuniendo a una comunidad de ingenieros apasionados y talentosos, hacedores, creadores de ideas y narradores para crear soluciones que mejoren el mundo. Colaborando con una amplia gama de empresas y organizaciones, Ebeling no solo lleva la innovación al siguiente nivel, sino que también comparte la emocionante historia de hacerlo.",
      //   image: require("../assets/foro-go/speakers/webp/Mick-Ebeling.webp"),
      //   social: {
      //     webSite: "https://www.notimpossible.com/",
      //     facebook: "https://www.facebook.com/notimpossiblelabs",
      //     instagram: "https://www.instagram.com/notimpossible/",
      //     twitter: "https://twitter.com/NotImpossible",
      //     youtube: "",
      //     linkedIn:
      //       "https://www.linkedin.com/in/mickebeling/?original_referer=https%3A%2F%2Fwww.google.com%2F",
      //   },
      // },
      // {
      //   id: 10,
      //   name: "Jeffrey Cartwright",
      //   description: "Experto en Nearshoring",
      //   more: "Durante los últimos nueve años, Jeff ha liderado una firma de asesoría en gestión enfocada principalmente en áreas de estrategia empresarial, mejoras operativas y crecimiento de ingresos. Esta práctica se centra en empresas de productos de consumo e industriales y crea valor en todos los aspectos de las operaciones, desde la planificación de la demanda hasta la fabricación, abastecimiento y distribución. Al fortalecer estas áreas, se facilita el crecimiento orgánico a través de nuevos productos y canales. Jeff cuenta con experiencia directa en más de 30 adquisiciones completadas que abarcan desde la diligencia debida hasta la aceleración de la creación de valor (planes de 100 días) y el logro de un crecimiento significativo de ingresos y mejoras operativas multimillonarias.",
      //   image: require("../assets/foro-go/speakers/webp/jeffrey.webp"),
      //   social: {
      //     webSite: "",
      //     facebook: "",
      //     instagram: "",
      //     twitter: "",
      //     youtube: "",
      //     linkedIn: "",
      //   },
      // },
      // {
      //   id: 11,
      //   name: "Mamselle Torres",
      //   description: "Directora de Innovación de Grupo Modelo Ab-InBev",
      //   more: "Es Maestra en Business & Technology, Lic. En Administración de Empresas cuenta con una certificación en Top Management por The Wharton School University of Pennsylvania y Especialización en Economía Circular por la UCLA. Directora de Tech Innovation e Inteligencia Artificial Generativa en ABInBev Middle Americas Zone (MAZ) y en México - Grupo Modelo liderando la plataforma de Corporate Venturing de Grupo Modelo, Levadura de Ideas. Ha impulsado una estrategia que combina los conocimientos y las prácticas de la gestión emprendedora y la gestión general, promoviendo la innovación abierta y transversal soportando los retos del negocio, contribuyendo a la economía local y global, así como el intra-emprendimiento en la compañía.",
      //   image: require("../assets/foro-go/speakers/webp/mamselle.webp"),
      //   social: {
      //     webSite: "",
      //     facebook: "",
      //     instagram: "",
      //     twitter: "",
      //     youtube: "",
      //     linkedIn: "",
      //   },
      // },
      // {
      //   id: 12,
      //   name: "Antonio Torres",
      //   description: "CEO y co-fundador de LIEVANT STUDIO",
      //   more: "Ingeniero en Tecnologías Computacionales por el Tecnológico de Monterrey, Campus León. Antonio Torres es especialista en Comercio Electrónico y Marketing Digital. Es CEO y co-fundador de LIEVANT STUDIO, actualmente una de las agencias más importantes de Comercio Electrónico en México. Cuentan con más de 120 clientes activos en México, Estados Unidos y Canadá, y procesan más de 50,000 pedidos en línea diariamente. Antonio participa como analista en Comercio Electrónico en diversos medios y publicaciones. Entre las marcas que ha asesorado se encuentran: Cuadra, CASIO, Aéropostale, Flexi, Estafeta, MOBO, Levi's, Caja Popular Mexicana, entre otras. LIEVANT es partner estratégico de MercadoLibre, Amazon, Google, Facebook y PayPal, y tiene presencia en Ciudad de México, León, Monterrey y Charlotte en Estados Unidos.",
      //   image: require("../assets/foro-go/speakers/webp/antonio-torres.webp"),
      //   social: {
      //     webSite: "",
      //     facebook: "",
      //     instagram: "",
      //     twitter: "",
      //     youtube: "",
      //     linkedIn: "",
      //   },
      // },
      // {
      //   id: 13,
      //   name: "Dymna Concha Espinoza",
      //   description: "Scarola Fashion Consulting",
      //   more: "Dymna Concha Espinoza es licenciada en Industria del Vestido y Textiles por Brigham Young University y diplomada en Creación de Empresas por la Universidad Tecnológica de Querétaro. Su desarrollo profesional en la Industria del Vestido ha sido en las áreas de diseño, Desarollo del Producto y Calidad en el área de Producción. Ha trabajado para empresas con marcas propias así como en la industria maquiladora para firmas como Banana Republic, Gap, Old Navy, Levy’s, Hanes, Champion, Callaway, Ashworth, entre otras. Además de su experiencia en la industria, se ha desempeñado en la academia como directora de la carrera de Industria del Vestido en la UVM campus Querétaro. Actualmente cuenta con una consultoría independiente de creación de producto en la que de forma integral acompaña a nuevas marcas en sus procesos de diseño, desarrollo del producto, y proveeduría hasta su ejecución con manufactura local.",
      //   image: require("../assets/foro-go/speakers/webp/Dymna.webp"),
      //   social: {
      //     webSite: "",
      //     facebook: "",
      //     instagram: "",
      //     twitter: "",
      //     youtube: "",
      //     linkedIn: "",
      //   },
      // },
      // {
      //   id: 14,
      //   name: "Eduardo García Lecuona",
      //   description:
      //     "Presidente Ejecutivo y Director General de Intercam Grupo Financiero",
      //   more: "Fundó en 1987 Intercam Grupo Financiero y Empresarial. Actualmente es Presidente del Consejo y Presidente Ejecutivo de Intercam Grupo Financiero, Intercam Banco, Casa de Bolsa y Fondos de Inversión, Intercam Securities, Futures, Quanta Shares, Grupo Intercam e Intercam Banco Internacional en Puerto Rico. Creador de la Fundación Intercambiando México para la conservación con impacto social y miembro del Patronato del Hospital Juárez de México. Es Ingeniero Industrial egresado de la Universidad Iberoamericana, con Posgrado en Ingeniería Económica y Administración de la Producción de la Universidad de Columbia de New York, y Diploma del Programa de Alta Dirección de Empresas y Gobierno Corporativo.",
      //   image: require("../assets/foro-go/speakers/webp/eduardo.webp"),
      //   social: {
      //     webSite: "",
      //     facebook: "",
      //     instagram: "",
      //     twitter: "",
      //     youtube: "",
      //     linkedIn: "",
      //   },
      // },
    ],
    speakersData: [],
    updateSpeakerInfo: (data) => set({ speakersInfo: [...data] }),
    updateProgramInfo: (data) => set({ programList: [...data] }),
    updateSponsorInfo: (data) => set({ viewPartner: [...data] }),

    updateContactData: (data) =>
      set({
        contactLocation: data.location,
        contactEmail: data.email,
        contactPhone: data.phone,
      }),
    updateSocialData: (data) => set({ socialData: data }),
    // incrementSpeakerInfo: () =>`  
    //   set((state) => ({ speakersInfo: state.speakersInfo })),
  }))
);

export default useStore;
